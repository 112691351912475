import {Box, Typography, IconButton, Paper, Grid, Button} from '@mui/material';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useAuth0} from '@auth0/auth0-react';
import EditIcon from '@mui/icons-material/Edit';
import listen from '../../assets/images/FC-Listen.svg';
import {useUserProfile} from '../../context/userProfileContext';
/**
 * LearnAbout component.
 * @return {JSX.Element} The LearnAbout component.
 */
export default function profileContent() {
  const {user} = useAuth0();
  const {userProfile} = useUserProfile();
  const navigate = useNavigate();

  const handleProfileEdit = () => {
    // TBD: add logic to edit profile
  };

  const handleGroupClick = () => {
    navigate('/app');
  };

  const shareLabel = () => {
    return (
      <Box m={2} display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        width={'90%'}
        justifyItems={'start'}>
        <Box display={'flex'}
          flexDirection={'column'}>
          <img src={listen} alt="listen" style={{width: '80px'}} />
          <br></br>
          <Typography variant={'body1'}><b>Listening</b>
          </Typography>
        </Box>
        <Box ml={5}>
          <Typography>Listening skills describe how you understand
           and pay attention to other people.
          </Typography>
          <br></br>
          <Typography variant={'body1'}>
            <span><b>Talk Target: &nbsp;</b></span>
            <span>Show appreciation for or
      agreement with someone else&apos;s idea.</span>
          </Typography>
        </Box>
      </Box>
    );
  };

  const groupCard = () => {
    return (
      <Button variant='contained'
        color='primary'
        onClick={handleGroupClick}
        style={{
          width: '85%',
          margin: '10px'}}>
        <Paper sx={{
          width: '100%',
          display: 'flex',
          padding: '20px',
        }}>
          <Grid container width={'100%'}>
            <Grid item xs={3}>
              <Typography variant='h6'>
              Demo Group
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Box display={'flex'}
                flexDirection={'column'}
                alignItems={'start'}
                justifyItems={'start'}>
                <Typography variant='body1'>
                  <b>Focus Areas:</b>
                &nbsp;&nbsp;Listening, Self-Expression
                </Typography>
                <Typography variant='body1'>
                  <b>Description:</b>
                &nbsp;&nbsp;This group is a demo for
                  testing the application with a group of users.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Button>
    );
  };

  return (
    <Box p={2} width='100%' display='flex'
      justifyContent={'center'}
      flexDirection={'column'}
      alignItems={'center'}>
      <Box display='flex'
        alignItems='center'
        width='100%' justifyContent='center' position='relative'>
        <Typography variant='h5'>
          {user.name}
        </Typography>
        <IconButton
          size="small"
          sx={{
            position: 'absolute',
            right: '10%'}}
          onSubmit={handleProfileEdit}
        >
          <EditIcon />
        </IconButton>
      </Box>
      <hr style={{width: '90%'}}></hr>
      <Box display={'flex'}
        width={'90%'}
        margin={2}
        flexDirection={'row'}
        justifyContent={'left'}>
        <Typography variant='body1'>
          <b>Display Name:</b>
        </Typography>
        <Typography variant='body1'>
          &nbsp;&nbsp;{userProfile.displayName}
        </Typography>
      </Box>
      <Box display={'flex'}
        width={'90%'}
        margin={2}
        flexDirection={'row'}
        justifyContent={'left'}>
        <Typography variant='body1'>
          <b>Age:</b>
        </Typography>
        <Typography variant='body1'>
          &nbsp;&nbsp;{userProfile.age}
        </Typography>
      </Box>
      <Box p={2} width='100%' display='flex'
        justifyContent={'center'}
        flexDirection={'column'}
        alignItems={'center'}>
        <Box display='flex'
          alignItems='center'
          width='100%' justifyContent='center' position='relative'>
          <Typography variant='h5'>
          Focus Areas
          </Typography>
        </Box>
        <hr style={{width: '90%'}}></hr>
      </Box>

      {shareLabel()}
      <Box display='flex'
        alignItems='center'
        width='100%' justifyContent='center' position='relative'>
        <Typography variant='h5'>
          Groups
        </Typography>
      </Box>
      <hr style={{width: '90%'}}></hr>
      <Typography variant='body1'>
        select Demo Group to navigate to the demo.
      </Typography>
      {groupCard()}
    </Box>
  );
}
