// src/App.js
import {ThemeProvider}
  from '@mui/material/styles'; // Import ThemeProvider and createTheme
// eslint-disable-next-line max-len
import React, {useState} from 'react';
import '@fontsource/poppins/200.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/800.css';
import AuthenticationGuard from './components/common/authentication-guard';
import {lightTheme as theme} from './assets/theme';
import Home from './components/marketingSite/pages/home';
import Product from './components/marketingSite/pages/product';
import ContactUs from './components/marketingSite/pages/contactUs';
import Waitlist from './components/marketingSite/pages/waitlist';
import Soon from './components/marketingSite/pages/comingSoon';
import Profile from './components/profile/profile';
import SelectedIdContext from './models/selectedContext';
import SessionSummary from './components/sessionSummary/summaryPage';
import {UserProfileProvider} from './context/userProfileContext';

import {Route, Routes} from 'react-router-dom';

import logger from './logger';
import AppHome from './components/videoApp/appHome';


const App = () => {
  logger.info('App load successful!');
  const [selectedId, setSelectedId] = useState(null);
  return (
    <ThemeProvider theme={theme}>
      <SelectedIdContext.Provider value={{selectedId, setSelectedId}}>
        <UserProfileProvider>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/product' element={<Product />} />
            <Route path='/about' element={<Home />} />
            <Route path='/contact' element={<ContactUs />} />
            <Route path='/waitlist' element={<Waitlist/>} />
            <Route path='/soon' element={<Soon/>} />
            <Route
              path='/profile'
              element={<AuthenticationGuard component={Profile} />}
            />
            <Route
              path='/session/:sessionId'
              element={<AuthenticationGuard component={SessionSummary} />}
            />
            <Route
              path='/app'
              element={<AuthenticationGuard component={AppHome} />}
            />
          </Routes>
        </UserProfileProvider>
      </SelectedIdContext.Provider>
    </ThemeProvider>
  );
};

export default App;

