// src/components/common/authentication-guard.js
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {withAuthenticationRequired, useAuth0} from '@auth0/auth0-react';
import {useUserProfile} from '../../context/userProfileContext';
import getUserProfile from '../../api/getProfile';

const AuthenticationGuard = ({component: Component}) => {
  const {user, isAuthenticated} = useAuth0();
  const [profile, setProfile] = useState(null);
  const {setUserProfile} = useUserProfile();
  // eslint-disable-next-line max-len
  console.log(`debugging auth guard user: ${JSON.stringify(user)} and isAuthenticated: ${isAuthenticated}`);

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (user && isAuthenticated) {
        try {
          const profile = await getUserProfile(user.sub);
          setProfile(profile);
        } catch (error) {
          console.error('Error fetching user profile: ', error);
        }
      };
    };
    user && isAuthenticated && fetchUserProfile();
  }, [user, isAuthenticated]);

  useEffect(() => {
    setUserProfile && profile &&
    setUserProfile(profile);
  }, [profile]);

  const AuthenticatedComponent = withAuthenticationRequired(Component, {
    onRedirecting: () => <div>Loading ...</div>,
  });

  return <AuthenticatedComponent />;
};

AuthenticationGuard.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default AuthenticationGuard;
