/* eslint-disable max-len */

import React, {
  useRef,
  useMemo,
  createContext,
  useState,
} from 'react';

import {Box, Grid} from '@mui/material';
import Call from './videoCall';
import {DailyAudio} from '@daily-co/daily-react';
import VideoControls from './videoControls';
import PropTypes from 'prop-types';
import ChatStream from '../chat/chatstream';
import TargetTips from '../talkTargets/talkTargetTips';
import {WidgetBar} from '../../marketingSite/sections/widgetBar';
import TimelineAgenda from '../talkTargets/timelineAgenda';
import useEventSource from '../../../hooks/useEventSource';
/* We decide what UI to show to users based on the
state of the app, which is dependent on the state of the call
object: see line 137. */
const STATE_IDLE = 'STATE_IDLE';
const STATE_ERROR = 'STATE_ERROR';


export const DataContext = createContext();

/**
 * Video App page, nests between headers on the App Home Page.
 * conditional rendering based on the state of the app, which is
 * dependent on the state of the call object.
 * @return {JSX.Element} - Rendered component
 */
const VideoApp = ({
  targetData,
  localSessionId,
  appState,
  setAppState,
}) => {
  const callRef = useRef(null);
  const serverEndpoint = process.env.REACT_APP_PROSOCIAL_BACKEND;
  const [timelineData, setTimelineData] = useState(targetData);
  const coachEventRef = useRef(null);


  const coachEndpoint = useMemo(() => {
    return localSessionId ?
      `${serverEndpoint}/users/${localSessionId}/feedback/` :
      // `${serverEndpoint}/users/test/feedback/` :
      null;
  }, [localSessionId, serverEndpoint]);


  const {chatHistory, context, targetPrompt} = useEventSource(
      coachEndpoint,
      coachEventRef,
      setTimelineData,
  );
  return (
    <DataContext.Provider value={context}>
      <Grid item xs={12} sx={{padding: '20px'}}>
        <TimelineAgenda data={timelineData} />
      </Grid>
      <Grid item xs={8}>
        <Grid container direction='row'
          sx={{
            minHeight: {
              xs: '300px', // minHeight on extra-small screens and up
              sm: '400px', // minHeight on small screens and up
              md: '500px', // minHeight on medium screens and up
              lg: '600px', // minHeight on large screens and up
              xl: '700px', // minHeight on extra-large screens and up
            },
            minWidth: {
              xs: '300px', // minHeight on extra-small screens and up
              sm: '400px', // minHeight on small screens and up
              md: '500px', // minHeight on medium screens and up
              lg: '600px', // minHeight on large screens and up
              xl: '700px', // minHeight on extra-large screens and up
            },
            height: '90vh',
            // backgroundColor: 'grey.100',
            overflow: 'hidden',
          }}>
          <Grid item xs={12} p={1} height="85%" ml={2}>
            <div style={{height: '100%'}} ref={callRef}>
              <VideoControls />
              <Box
                sx={{
                  flex: 1,
                  backgroundColor: '#000',
                  alignContent: 'center',
                  textAlign: 'center',
                  height: '95%',
                }}
              >
                <Call onCameraError={() => setAppState(STATE_ERROR)} />

              </Box>
            </div>
            <DailyAudio />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}
        sx={{
          overflowY: 'auto',
          minHeight: {
            xs: '300px', // minHeight on extra-small screens and up
            sm: '400px', // minHeight on small screens and up
            md: '500px', // minHeight on medium screens and up
            lg: '600px', // minHeight on large screens and up
            xl: '700px', // minHeight on extra-large screens and up
          },
          height: '90vh',
        }}>
        <Box padding={'20px'} sx={{
          height: '98%',
          border: '0px solid black',
          background: 'white',
        }}>
          <TargetTips talkTargetTip={targetPrompt}/>
          <ChatStream chatHistory={chatHistory} />
        </Box>
      </Grid>
      <Grid item xs={12}
        height="15%"
        sx={{
          overflowY: 'auto',
        }}>
        <Box padding={'0px'} marginLeft={'100px'} sx={{
          border: '0px solid red',
        }}>{appState != STATE_IDLE && <WidgetBar data={context} />}
        </Box>
      </Grid>
    </DataContext.Provider>
  );
};

VideoApp.propTypes = {
  targetData: PropTypes.array,
  localSessionId: PropTypes.string,
  appState: PropTypes.string,
  setAppState: PropTypes.func,
};

VideoApp.defaultProps = {
  label: 'Target Label',
  activeSpeaker: 'Lara',
  explanation: `Talk targets are participation goals that are unique to you.
  Each target includes an explanation and suggestions to help you participate.`,
  targetData: [
    {label: 'Default Step 1', checkstate: true, explanation: 'This is the first step'},
    {label: 'Default Step 2', checkstate: true, explanation: 'This is the second step'},
    {label: 'Default Step 3', checkstate: false, explanation: 'This is the third step'},
    {label: 'Default Step 3', checkstate: false, explanation: 'This is the third step'},
    {label: 'Default Step 3', checkstate: false, explanation: 'This is the third step'},
  ],
};

export default VideoApp;
