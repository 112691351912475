// src/context/UserProfileContext.js
import React, {createContext, useState, useContext} from 'react';
import PropTypes from 'prop-types';

const UserProfileContext = createContext(null);

export const UserProfileProvider = ({children}) => {
  const [userProfile, setUserProfile] = useState(null);

  return (
    <UserProfileContext.Provider value={{userProfile, setUserProfile}}>
      {children}
    </UserProfileContext.Provider>
  );
};

UserProfileProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useUserProfile = () => {
  return useContext(UserProfileContext);
};
