import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ContextualHeader from '../common/contextualHeader.jsx';
import DesktopFooter from '../common/footer.jsx';
import {Box, Grid} from '@mui/material';
import getUserProfile from '../../api/getProfile';
import {fetchRoomData} from '../../api/dailyapi';
// import {topNavLinks} from '../../models/navlinks.js';
import VideoApp from './video/videoAppPage.jsx';
import {useAuth0} from '@auth0/auth0-react';
import {DailyProvider} from '@daily-co/daily-react';
import {companyLinks,
  resourcesLinks, partnerLinks} from '../../models/navlinks.js';
import CheckIn from './checkin/checkInPage.jsx';
import VideoError from './video/videoTiles/videoError';
import VideoJoin from './prejoin/videoJoinPage.jsx';
import logger from '../../logger.js';
import getTalkTargetTip from '../../api/getTargetTip.js';

const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
import {CircularProgress} from '@mui/material';

const STATE_IDLE = 'STATE_IDLE';
const STATE_ERROR = 'STATE_ERROR';
const STATE_CHECKIN = 'STATE_CHECKIN';
const STATE_READY = 'STATE_READY';
const STATE_JOINED = 'STATE_JOINED';

/**
 * container page for the video calling app.
 * on load, it will get the access token for the user. Once the token is
 * provided by daily, it will render the video app.
 * @param {Object} props - The component props.
 * @param {Object[]} props.companyLinks - The company links.
 * @param {Object[]} props.resourcesLinks - The resources links.
 * @param {Object[]} props.partnerLinks - The partners links.
 * @param {number} props.logoWidth - The width of the logo.
 * @return {JSX.Element} - The component JSX.
 */
export default function AppHome({

  companyLinks,
  resourcesLinks,
  partnerLinks,
  logoWidth,
}) {
  const auth = useAuth0();
  const [accessToken, setAccessToken] = useState();
  const [room, setRoom] = useState(null);
  const [appState, setAppState] = useState(STATE_IDLE);
  const [checkInMessage, setCheckInMessage] = useState('');
  const [localSessionId, setLocalSessionId] = useState('');
  const [targetData, setTargetData] = useState([]);
  const {user} = useAuth0();

  const fetchRoom = async () => {
    try {
      const roomData = await fetchRoomData(accessToken, true);
      setRoom(roomData);
    } catch (error) {
      setAppState(STATE_ERROR);
    };
  };

  const triggerTalkTargetTip = async (userId) => {
    logger.info('triggering talk target tip');
    try {
      await getTalkTargetTip(userId);
    } catch (error) {
      logger.error('Error fetching talk target tip', error);
    };
  };


  const fetchUserProfile = async (userId) => {
    try {
      const profile = await getUserProfile(userId);
      setTargetData(profile.targets);
    } catch (error) {
      logger.error('Error fetching user profile', error);
      setAppState(STATE_ERROR);
    };
  };

  useEffect(() => {
    accessToken && fetchRoom();
    user && fetchUserProfile(user.sub);
  }, [accessToken]);

  useEffect(() => {
    console.log('room', room);
    if (appState === STATE_IDLE && room) {
      setAppState(STATE_CHECKIN);
    }
  }, [room]);

  /**
   * Submits the check-in data.
   * @param {any} data - The check-in data to be submitted.
   */
  function checkInSubmit(data) {
    setCheckInMessage(data);
    logger.info(checkInMessage);
    setAppState(STATE_READY);
  };

  /**
   * if there is a room, we initialize the DailyProvider
   * with the room url and token
   * if there is no room, we display a loading spinner
   * if there is an error, we display an error message
   * if the user has not checked in, we display the check-in form
   * if the user has checked in, we display the call screen
   * @return {React.ReactNode} The rendered component.
   */
  const callConditionalRender = () => {
    console.log(`rendering for app state: ${appState}`);
    if (appState === STATE_ERROR) {
      return <VideoError />;
    } else if (appState === STATE_IDLE) {
      return <Box display={'flex'} width='100vw' height={'100vh'}
        alignItems={'center'} justifyContent={'center'}>
        <CircularProgress /></Box>;
    } else if (appState === STATE_CHECKIN) {
      return <CheckIn handleSubmit={checkInSubmit}></CheckIn>;
    } else if (appState === STATE_JOINED) {
      return <VideoApp targetData={targetData}
        localSessionId={localSessionId}
        appState={appState}
        setAppState={(state) => setAppState(state)}></VideoApp>;
    } else {
      return <VideoJoin startCall={(event) => {
        console.log('starting call for user and session and meeting',
            JSON.stringify(room));
        user && triggerTalkTargetTip(user.sub);
        setAppState(STATE_JOINED);
        setLocalSessionId(event.participants.local.session_id);
      }} sendError={() => setAppState(STATE_ERROR)} />;
    }
  };

  const getToken = async () => {
    try {
      logger.info(`token audience value: ${audience}`);
      setAccessToken(await auth.getAccessTokenSilently({
        authorizationParams: {
          audience: audience,
          scope: `profile email demo join:demo view:app
          view:analytics join:video`,
        },
      }));
    } catch (e) {
      logger.info(`unable to get access token for user: ${e.message}`);
    }
  };

  useEffect(() => {
    logger.info(`getting token for user ${JSON.stringify(auth.user)}`);
    getToken();
  }, []);

  return (

    <Box display={'flex'} flexDirection={'column'}>
      <Box sx={{position: 'sticky', top: 0, zIndex: 1000}}>
        <ContextualHeader />
      </Box>
      {accessToken ?
          <Grid container direction='row'
            sx={{alignItems: 'center', justifyContent: 'center'}}>
            { room ?
            <DailyProvider url={room.room_url}
              token={room.token} userName={user.name}
              dailyConfig={{useDevicePreferenceCookies: true}}>
              {callConditionalRender()}
            </DailyProvider> : <CircularProgress />}
          </Grid> :
      <Box display={'flex'} m={2} width='100vw' height={'100vh'}
        alignItems={'center'} justifyContent={'center'}>
        <CircularProgress />
      </Box>
      }
      <DesktopFooter companyLinks={companyLinks}
        partnersLinks={partnerLinks}
        resourcesLinks={resourcesLinks}
        logoWidth={logoWidth}/>
    </Box>

  );
}

AppHome.propTypes = {
  companyLinks: PropTypes.array,
  resourcesLinks: PropTypes.array,
  partnerLinks: PropTypes.array,
  logoWidth: PropTypes.number,
};


AppHome.defaultProps = {
  companyLinks: companyLinks,
  resourcesLinks: resourcesLinks,
  partnerLinks: partnerLinks,
  logoWidth: 40,
};
