import {Grid, Typography, ButtonBase, styled, Box} from '@mui/material';
import React, {useState} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import AccountCircle from '@mui/icons-material/AccountCircle';
import groupsIcon from '../../assets/images/Groups.png';
import friendsIcon from '../../assets/images/Friends.png';
import {useTheme} from '@mui/material';
/**
 * Renders the AppLeftNav component.
 * @return {JSX.Element} The rendered AppLeftNav component.
 */
export default function AppLeftNav() {
  const {user} = useAuth0();
  const [selectedId, setSelectedId] = useState(null);
  /**
 * Callback function for click event.
 */
  const theme = useTheme();

  const ImageButton = styled(ButtonBase)(({theme, selected}) => ({
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'start',
    'padding': theme.spacing(1),
    'borderRadius': '0 10px 10px 0', // Rounded corners on the left side
    'border': '1px solid transparent', // Optional: add a border if needed
    'backgroundColor': selected ?
     theme.palette.grey[300] : 'transparent', // Change color when selected
    '&:hover': {
      backgroundColor: theme.palette.grey[400], // Lighter grey on hover
    },
    // Additional styles for the clicked state
    // can be handled through component state or props
    '& .MuiTypography-body2': {
      marginLeft: theme.spacing(1),
    },
    // Example of styling an img element within your button,
    // assuming icons are img tags
    '& img': {
      width: 24, // Adjust size as needed
      height: 24, // Adjust size as needed
    },
  }));

  const buttons = [
    {id: 0, icon: <AccountCircle />, text: user.name},
    {id: 1, icon: groupsIcon, text: 'Groups'},
    {id: 2, icon: friendsIcon, text: 'Friends'},
    // Add more buttons as needed
  ];
  return (
    <section id='AppLeftNav'>
      <Grid container direction={'column'}>
        <Grid item xs={12}>
          {buttons.map((button) => (
            <Box key={button.id} style={{
              'display': 'flex',
              'width': '100%',
              'm': '5px'}}>
              <ImageButton
                key={button.id}
                selected={selectedId === button.id}
                onClick={() => setSelectedId(button.id)}
                style={{ml: '20px', width: '250px'}}>
                {button.id === 0 && <AccountCircle style={{
                  color: theme.palette.primary.main,
                  fontSize: '4rem',
                }}/>}
                {button.id !== 0 && <img src={button.icon} alt={button.text}
                  style={{width: '80px', height: 'auto'}}/>}
                <Typography variant={'body2'}>{button.text}</Typography>
              </ImageButton>
            </Box>
          ))}
        </Grid>
      </Grid>
    </section>
  );
}

