import {Box, Typography} from '@mui/material';
import React from 'react';
import robotspeech from '../../assets/images/robot-speech-transcription.svg';
import StarterForm from './starterForm';
import {useUserProfile} from '../../context/userProfileContext';
/**
 * LearnAbout component.
 * @return {JSX.Element} The LearnAbout component.
 */
export default function LearnAbout() {
  const {userProfile}= useUserProfile();
  console.log(`got userProfile from context: ${JSON.stringify(userProfile)}`);
  return (
    <div>
      <Box p={2} width='100%' display='flex'
        justifyContent={'left'}
        flexDirection={'row'}
        alignItems={'center'}>
        <img src={robotspeech} alt='robot-speech'
          style={{marginRight: '80px'}} />
        <Box display='flex'
          flexDirection={'column'}
          m={'10px'}>
          <Typography variant='h3'>
         Welcome {userProfile && userProfile.name}!
          </Typography>
          <Typography mt={'5px'} variant='body1'>
            We are so glad you&apos;re here! Lets get started. <br />
            Before we begin,
            we need to ask a few questions to get to know you better.
          </Typography>
        </Box>

      </Box>
      <Box width='100%' display='flex'
        mt={'-20px'}
        justifyContent={'center'}
        flexDirection={'row'}
        alignItems={'center'}>
        <StarterForm></StarterForm>
      </Box>
    </div>
  );
}

