/* eslint-disable max-len */
import React, {useState} from 'react';
import {Box, Typography, Grid, Dialog, DialogTitle, DialogContent, List, ListItem, ListItemText} from '@mui/material';
import PropTypes from 'prop-types';
import {ArrowUpward, ArrowDownward} from '@mui/icons-material';

const sessionSummary = 'Brief summary of meeting topics, contents, etc.';
const sessionMetrics = [
  {label: 'Participation', value: '+', color: 'green', subMetrics: {'Talk Time': '10 mins', 'Participation %': '75%'}},
  {label: 'Listening', value: '+', color: 'green', subMetrics: {'Talk Time': '15 mins', 'Participation %': '90%'}},
];

const SessionReview = ({summary, metrics}) => {
  const [open, setOpen] = useState(false);
  const [selectedMetric, setSelectedMetric] = useState(null);

  const handleOpen = (metric) => {
    setSelectedMetric(metric);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedMetric(null);
  };
  return (
    <Box display={'flex'}
      flexShrink={'2'}
      style={{width: '100%',
        maxHeight: '250px',
        backgroundColor: 'white',
        border: '0.5px solid rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)'}}>
      <Box p={2} width='100%' display='flex'
        justifyContent={'center'}
        flexDirection={'column'}
        alignItems={'center'}>
        <Box display='flex'
          alignItems='center'
          width='100%' justifyContent='center' position='relative'>
          <Typography variant='h5'>
        Session Review
          </Typography>
        </Box>
        <hr style={{width: '90%'}}></hr>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography variant="body1"
              sx={{mb: 2}}>Summary: {summary}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{display: 'flex', justifyContent: 'space-around'}}>
              {metrics.map((metric, index) => (
                <Box
                  key={index}
                  justifyContent={'center'}
                  alignContent={'center'}
                  sx={{
                    'textAlign': 'center',
                    'cursor': 'pointer',
                    '&:hover': {opacity: 0.8},
                  }}
                  onClick={() => handleOpen(metric)}
                >
                  <Box width={'100%'} display='flex' justifyContent={'center'}>
                    <Box
                      sx={{
                        width: 50,
                        height: 50,
                        borderRadius: '50%',
                        backgroundColor: metric.color,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                        fontSize: '1.5rem',
                      }}
                    >
                      <Typography variant="h6">{metric.value === '+' ? <ArrowUpward /> : <ArrowDownward />}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography variant="body2">{metric.label}</Typography>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{selectedMetric?.label} Sub-Metrics</DialogTitle>
        <DialogContent>
          <List>
            {selectedMetric &&
              Object.entries(selectedMetric.subMetrics).map(([key, value]) => (
                <ListItem key={key}>
                  <ListItemText primary={key} secondary={value} />
                </ListItem>
              ))}
          </List>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

SessionReview.propTypes = {
  summary: PropTypes.string,
  metrics: PropTypes.array,
};

SessionReview.defaultProps = {
  summary: sessionSummary,
  metrics: sessionMetrics,
};


export default SessionReview;
