
import React, {useEffect, useState} from 'react';
import {Box, Button, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import {useDaily, useDailyEvent} from '@daily-co/daily-react';
import CircularProgress from '@mui/material/CircularProgress';

const VideoJoin = ({startCall, sendError}) => {
  const [isJoining, setIsJoining] = useState(false);
  const daily = useDaily();

  const start = (event) => {
    event.preventDefault();
    setJoiningFlag();
    console.log('starting call');
    daily && daily.join();
  };

  const setJoiningFlag = () => {
    console.log('setting joining flag');
    !isJoining && setIsJoining(true);
  };
  useDailyEvent('joining-meeting', setJoiningFlag);
  useDailyEvent('joined-meeting', startCall);
  useDailyEvent('error', sendError);

  useEffect(() => {
    console.log('useEffect on daily', JSON.stringify(daily));
  }, [daily]);

  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
      <Typography variant="h1" mt={5} textAlign='center' sx={{
        fontSize: {xs: '1rem', sm: '1.2rem', m: '1.5rem'}}}>
      CALL DEMO <p></p>
      </Typography>
      {isJoining && <CircularProgress sx={{color: 'black'}} />}
      {!isJoining && <div> <Typography textAlign='center'
        variant="body1"
        sx={{
          color: 'black',
          fontSize: {xs: '.7rem', sm: '1rem'},
        }}>
        Tap the button below to join
      </Typography>
      <Box width={'100%'} display='flex'
        mt={5} justifyContent={'center'}>
        {daily &&
        <Button variant="contained" onClick={start} disabled={isJoining}>
      JOIN
        </Button>}
      </Box>
      <Box width={'100%'} display='flex'
        m={5} justifyContent={'center'}>
        <Typography variant="body1" textAlign="center" sx={{
          color: '#f44336',
          fontSize: {xs: '.7rem', sm: '1rem'}}}>
      IMPORTANT: Select “Allow” to use your camera and mic for
      this call if prompted.
        </Typography>
      </Box>
      </div>
      }
    </Box>);
};

VideoJoin.propTypes ={
  startCall: PropTypes.any,
  sendError: PropTypes.any,
};

VideoJoin.defaultProps = {
  startCall: () => {
    console.log('starting call');
  },
};

export default VideoJoin;
