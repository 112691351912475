import React, {useState, useEffect, useContext} from 'react';
import MoodMeterTable from '../checkin/moodMeter';
import {Box, Typography, TextField, Button} from '@mui/material';
import DemoImg from '../../profile/demoPic';
import Eddie from '../../../assets/images/boy-persona.png';
import Annie from '../../../assets/images/girl-persona.png';
import PropTypes from 'prop-types';
import SelectedIdContext from '../../../models/selectedContext';
/**
 * Renders the CheckIn component.
 * @param {Function} handleSubmit - The function to handle the form submission.
 * @return {JSX.Element} The rendered CheckIn component.
 */
const CheckIn = ({handleSubmit}) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const {selectedId} = useContext(SelectedIdContext);
  const [mood, setMood] = useState({label: null, hex: null});
  const img = (selectedId === 'Annie'? Annie : Eddie);
  const handleMoodClick = (data) =>{
    data && console.log(`handling mood click for ${data.hex}`);
    setMood(data);
  };
  useEffect(() => {
    mood.hex && console.log(`mood set to: ${mood.hex} for:  ${selectedId}`);
  }, [mood]);

  return (
    <Box p={2} width={'90%'}
      overflowY='auto'>
      <Typography variant="h6" sx={{
        textAlign: 'center', width: '100%'}}>Hello {selectedId}!
         Before you join, let&apos;s do a quick check-in. </Typography>
      <Box sx={{display: 'flex', mb: 4,
        justifyContent: 'center', width: '100%'}}>
        <DemoImg img={img} moodColor={mood.hex}></DemoImg>
      </Box>
      <Typography variant="body1" sx={{
        textAlign: 'left', width: '100%'}}>
                        How are you feeling today? <p></p>
      </Typography>
      <MoodMeterTable handleMoodClick={handleMoodClick}></MoodMeterTable>
      <p></p>
      <Typography variant="body1" sx={{
        textAlign: 'left', width: '100%'}}>
                        Is there anything on your mind that
                         you want to share with the group? <p></p>
      </Typography>
      <Box mt={2} sx={{width: '100%', textAlign: 'center'}}>
        <TextField
          label="Check In"
          variant="outlined"
          value={inputValue}
          multiline
          rows={4}
          onChange={handleInputChange}
          sx={{marginRight: 2,
            width: '100%'}}
        />
        <p></p>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
                    I&apos;m Ready!
        </Button>
      </Box>
    </Box>
  );
};

CheckIn.propTypes = {
  selectedId: PropTypes.string,
  handleSubmit: PropTypes.func,
};

CheckIn.defaultProps = {
  selectedId: 'Annie',
  handleSubmit: () => {},
};
export default CheckIn;

