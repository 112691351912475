import React from 'react';
import {Grid} from '@mui/material';
import PropTypes from 'prop-types';
import {VerticalButtonGroup} from
  '../marketingSite/navigation/verticalButtonGroup';
import {companyLinks, partnerLinks,
  resourcesLinks} from '../../models/navlinks';
// default links for nav menus


export const LinkTable = ({company, resources, partners}) => {
  return (
    <Grid container spacing={8} alignItems="center">
      <Grid item xs={12} sm={6} md={4}>
        <VerticalButtonGroup links={company} title="Company" />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <VerticalButtonGroup links={resources} title="Resources" />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <VerticalButtonGroup links={partners} title="Partners" />
      </Grid>
    </Grid>
  );
};

LinkTable.propTypes = {
  /**
   * a list of key-value pairs of navigation links with the link name and path:
   *  const links = [{ name: 'Partners', path: '/#partners' },..],
   */
  company: PropTypes.array,
  /**
   * a list of key-value pairs of navigation links with the link name and path:
   *  const links = [{ name: 'Partners', path: '/#partners' },..],
   */
  resources: PropTypes.array,
  /**
   * a list of key-value pairs of navigation links with the link name and path:
   *  const links = [{ name: 'Partners', path: '/#partners' },..],
   */
  partners: PropTypes.array,
};

LinkTable.defaultProps = {
  company: companyLinks,
  resources: resourcesLinks,
  partners: partnerLinks,
};
