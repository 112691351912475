/* eslint-disable linebreak-style */
import React, {useState, useRef, useEffect} from 'react';
import {Box, Typography, Grid, Paper, Popover} from '@mui/material';
import CircleWithCheck from '../../common/checkMark';
import PropTypes from 'prop-types';


const TimelineAgenda = ({data}) => {
  // State for managing modal visibility and content
  const [modalContent, setModalContent] =
   useState({explanation: '', title: ''});
  const [anchorEl, setAnchorEl] = useState(null);
  const firstCircleRef = useRef(null);
  const circleParentRef = useRef(null);
  const [boxLocation, setBoxLocation] = useState({top: 0, left: 0, right: 0});
  // Function to han  dle mouse enter event
  const handleMouseEnter = (event, e, t) => {
    setAnchorEl(event.currentTarget);
    setModalContent({} = {explanation: e, title: t});
  };

  console.log('got targets from user profile', JSON.stringify(data));

  // Function to handle mouse leave event
  const handleMouseLeave = () => {
    setModalContent({explanation: '', title: ''});
    setAnchorEl(null);
  };

  const getPosition = () => {
    const childRect = firstCircleRef.current.getBoundingClientRect();
    const parentRect = circleParentRef.current.getBoundingClientRect();
    console.log('childRect', childRect);
    console.log('parentRect', parentRect);
    setBoxLocation({
      top: childRect.top - parentRect.top + childRect.height / 2,
      left: childRect.left - parentRect.left + childRect.width / 2,
      right: childRect.right - parentRect.left - childRect.width / 2,
    });
  };

  useEffect(() => {
    getPosition();

    const handleResize = () => {
      getPosition();
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box ref={circleParentRef} position="relative"
      top='10px' className="timeline-agenda-container">
      <Typography variant="h6" style={{
        textAlign: 'center', width: '100%'}}>
          Talk Targets
      </Typography>
      {/* Timeline Line */}
      <Box
        position="absolute"
        top={boxLocation.top + 'px'}
        left={boxLocation.left + 'px'}
        right={boxLocation.right + 'px'}
        height="4px"
        bgcolor="#515abb"
        zIndex="1"
      />

      <Grid container direction="row" alignItems="start">
        {data.map((item, index) => (
          <Grid item xs key={index} style={{position: 'relative', zIndex: 2}}>
            <Box>
              <Grid container direction="column" alignItems="center">
                {/* Checkbox */}
                <Grid item style={{alignSelf: 'center'}}>
                  <CircleWithCheck
                    ref={index === 0 ? firstCircleRef : null}
                    checked={item.checkstate} />
                </Grid>

                {/* Label */}
                <Grid item style={{alignSelf: 'center',
                  width: '100%', height: '50px'}}>

                  <Typography
                    onMouseEnter={(e) =>
                      handleMouseEnter(e, item.explanation, item.label)}
                    onMouseLeave={handleMouseLeave}
                    variant="caption"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      // transform: 'rotate(-35deg)',
                      // transformOrigin: 'right top',
                      width: '100%',
                      padding: '5px',
                      whiteSpace: 'normal',
                      textAlign: 'center',
                      overflowWrap: 'break-word',
                      // marginTop: '-15px',

                    }}
                  >

                    {item.label}

                  </Typography>

                </Grid>
              </Grid>
            </Box>
          </Grid>
        ))}
      </Grid>

      {/* Modal for displaying explanation */}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{pointerEvents: 'none'}}
        disableRestoreFocus
        onClose={handleMouseLeave}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper sx={{
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {modalContent.title}
          </Typography>
          <Typography id="modal-modal-description" sx={{mt: 2}}>
            {modalContent.explanation}
          </Typography>
        </Paper>
      </Popover>
    </Box>
  );
};

// Separate default variable for checkData
const checkDataDefault = [
  {label: 'Default Step 1',
    checkstate: true, explanation: 'This is the first step'},
  {label: 'Default Step 2',
    checkstate: true, explanation: 'This is the second step'},
  {label: 'Default Step 3',
    checkstate: false, explanation: 'This is the third step'},
  {label: 'Default Step 4',
    checkstate: false, explanation: 'This is the fourth step'},
  {label: 'Default Step 5', checkstate: false}, // No explanation,
];

TimelineAgenda.propTypes = {
  data: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        checkstate: PropTypes.bool.isRequired,
        explanation: PropTypes.string,
      }),
  ).isRequired,
};

TimelineAgenda.defaultProps = {
  data: checkDataDefault,
};

export default TimelineAgenda;
