import React from 'react';
import AppHeader from '../common/appHeader';
import DesktopFooter from '../common/footer';
import {Box} from '@mui/material';
import AppLeftNav from './appLeftNav';
import LearnAbout from './learnAbout';
import ProfileContent from './profileContent';
import {useUserProfile} from '../../context/userProfileContext';
/**
 * Renders the profile component.
 * @return {JSX.Element} The profile component.
 */
export default function Profile() {
  const {userProfile} = useUserProfile();
  userProfile &&
    console.log(`got user from context: ${JSON.stringify(userProfile)}`);
  if (userProfile) {
    return (
      <div id='UserProfileHome'
        style={{backgroundColor: '#F6F6F6',
          width: '100vw',
          height: '100%'}}>
        <AppHeader logoWidth={150}></AppHeader>
        <Box mt={'20px'}
          mb={'20px'}
          width={'calc(100% - 40px)'}
          display={'flex'}
          flexDirection={'row'}>
          <Box
            width={'260px'}>
            <AppLeftNav />
          </Box>
          <Box
            ml={'10px'}
            width={'calc(100% - 270px)'}>
            <Box style={{width: '100%',
              height: '100%',
              backgroundColor: 'white',
              border: '0.5px solid rgba(0, 0, 0, 0.1)',
              borderRadius: '10px',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)'}}>
              {(!userProfile || !userProfile.age)?
             (<LearnAbout></LearnAbout>) :
            <ProfileContent></ProfileContent>}
            </Box>
          </Box>
        </Box>
        <DesktopFooter></DesktopFooter>
      </div>
    );
  } else {
    return (
      <div>Loading...</div>
    );
  }
};
