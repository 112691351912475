/* eslint-disable max-len */
import React from 'react';
import {Box,
  Typography} from '@mui/material';
import SessionHighlightsContent from './sessionHighlightContent';
import PropTypes from 'prop-types';

const coachingData = [
  {
    category: 'Self-Expression',
    highlights: [
      'Great job validating others!',
      'When you said: “That thing you said”, it helped others understand blah blah',
    ],
    suggestions: [
      'do this suggestion',
      'and this one',
      'and also this',
    ],
  },
  {
    category: 'Engaging Others',
    highlights: [
      'Great job validating others!',
      'When you said: “That thing you said”, it helped others understand blah blah',
    ],
    suggestions: [
      'do this suggestion',
      'and this one',
      'and also this',
    ],
  },
  {
    category: 'Engaging Others',
    highlights: [
      'Great job validating others!',
      'When you said: “That thing you said”, it helped others understand blah blah',
    ],
    suggestions: [
      'do this suggestion',
      'and this one',
      'and also this',
    ],
  },
  {
    category: 'Engaging Others',
    highlights: [
      'Great job validating others!',
      'When you said: “That thing you said”, it helped others understand blah blah',
    ],
    suggestions: [
      'do this suggestion',
      'and this one',
      'and also this',
    ],
  },
];

const SessionHighlights = ({coachingData}) => {
  return (
    <Box style={{width: '100%',
      maxHeight: 'calc(100% - 250px)',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      backgroundColor: 'white',
      border: '0.5px solid rgba(0, 0, 0, 0.1)',
      borderRadius: '10px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)'}}>
      <Box p={2} width='100%' display='flex'
        height={'100%'}
        justifyContent={'center'}
        flexDirection={'column'}
        alignItems={'center'}
        overlfow='hidden'>
        <Box display='flex'
          height={'40px'}
          alignItems='center'
          width='100%' justifyContent='center' position='relative'>
          <Typography variant='h5'>
        Session Highlights
          </Typography>
        </Box>
        <hr style={{width: '90%'}}></hr>
        <SessionHighlightsContent coachingData={coachingData}/>
      </Box>
    </Box>
  );
};

SessionHighlights.propTypes = {
  coachingData: PropTypes.array,
};

SessionHighlights.defaultProps = {
  coachingData: coachingData,
};


export default SessionHighlights;
